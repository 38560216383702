<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <button
              class="btn btn-success  btn-sm"
              data-toggle="modal"
              data-target="#ServicesModal"
            >
              Add QRCode Item
            </button>

            <!-- Modal -->
            <div
              class="modal fade"
              id="ServicesModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="ServicesModalTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Add QRCode
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <label for="name">Name</label>
                      <input
                        type="text"
                        v-model="formData.FullName"
                        class="form-control"
                        placeholder="enter qr item name "
                      />
                    </div>

                    <div class="form-group">
                      <label for="name">Cost</label>
                      <input
                        type="text"
                        v-model="formData.cost"
                        class="form-control"
                        placeholder="enter cost "
                      />
                    </div>

                    <div class="form-group">
                      <label for="name">Description</label>
                      <textarea
                        name="description"
                        v-model="formData.Description"
                        class="form-control"
                        placeholder="enter brief description here"
                        rows="5"
                      ></textarea>
                    </div>

                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        value="true"
                        v-model="formData.IsActive"
                        class="custom-control-input"
                        id="customCheckDisabled1"
                      />
                      <label
                        class="custom-control-label"
                        for="customCheckDisabled1"
                        >Is Active</label
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      @click="createQRcode"
                      type="button"
                      class="btn btn-primary"
                    >
                      <b-spinner v-if="loading" type="grow"></b-spinner>
                      <span v-else>Add QRCode Item</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template v-slot:cell(Edit)="row">
                  <button
                    @click="getData(row.item.id)"
                    type="button"
                    data-toggle="modal"
                    data-target="#editServiceModalCenter"
                    class="btn btn-success"
                  >
                    <i class="fa fa-pen" aria-hidden="true" title="Edit"></i>
                  </button>

                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="editServiceModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="editServiceModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5
                            class="modal-title"
                            id="editServiceModalLongTitle"
                          >
                            Edit QRCode Item
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="form-group">
                            <label for="fullname">FullName</label>
                            <input
                              type="text"
                              :value="service.fullName"
                              @change="updateFullname"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <label for="fullname">Cost</label>
                            <input
                              type="text"
                              :value="service.cost"
                              @change="updateCost"
                              class="form-control"
                            />
                          </div>
                          <div class="form-group">
                            <label for="description">Description</label>
                            <textarea
                              name="description"
                              class="form-control"
                              @change="updateDescription"
                              :value="service.description"
                              rows="5"
                            ></textarea>
                          </div>
                          <div class="form-group ml-4 form-check-inline">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              :value="service.isActive ? false : true"
                              :checked="service.isActive"
                              @change="onCheckboxChange"
                            />

                            <label class="form-check-label">Is Active</label>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            @click="SaveChanges"
                            data-dismiss="modal"
                            type="button"
                            class="btn btn-primary"
                          >
                            <b-spinner v-if="loading" type="grow"></b-spinner>
                            <span v-else>Save changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

               
              </b-table>
            </div>

            <div class="flex justify-center">
              <b-spinner
                v-if="loading"
                style="width: 3rem; height: 3rem;"
                label="Large Spinner"
              ></b-spinner>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import $ from "jquery";

// eslint-disable-next-line no-unused-vars

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Manage Services",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Manage QRCode Items",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "fullName", sortable: true, label: "Full Name" },
        { key: "cost", sortable: true },
        { key: "isActive", label: "Is Active" },
        { key: "Edit" },
       
      ],
      formData: {
        FullName: "",
        Description: "",
        cost: "",
        IsActive: false
      },
      tableData: [],
      service: {},
      loading: false
    };
  },
  computed: {
    qrcode_generator() {
      return this.$store.getters["pharmacy/getAllQRcodes"];
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.qrcode_generator && this.qrcode_generator.length;
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getQRCodeItems() {
      this.loading = true;
      this.$store.dispatch("pharmacy/GetQRCodeItems").then((response) => {
        this.tableData = response.data;
        this.loading = false;
      });
    },
    getData(id) {
      this.tableData.forEach((data) => {
        if (data.id === id) {
          this.service = data;
          console.log(this.service);
        }
      });
    },

    

    updateFullname(e) {
      this.$store.dispatch("pharmacy/QrupdateFullname", e.target.value);
    },
    updateDescription(e) {
      this.$store.dispatch("pharmacy/QrupdateDescription", e.target.value);
    },
    onCheckboxChange(e) {
      this.$store.dispatch("pharmacy/CheckboxChange", e.target.value);
    },
    updateCost(e) {
      this.$store.dispatch("pharmacy/QRupdateCost", e.target.value);
    },

    SaveChanges() {
      const data = {
        FullName:
          this.$store.state.pharmacy.qr_full_name || this.service.fullName,
        description:
          this.$store.state.pharmacy.qr_description || this.service.description,
        isactive:
          this.$store.state.pharmacy.CheckboxChange || this.service.isActive,
        cost: this.$store.state.pharmacy.qr_cost || this.service.cost,
        GUID: this.service.guid,
        timeStamp: this.service.timeStamp
      };
   this.loading = true
      this.$store
        .dispatch("pharmacy/QRCodeEdit", data)
        .then((response) => {
          this.loading = false
          if (response) {
            $("#editServiceModalCenter").modal("hide");
            this.getQRCodeItems();
            // location.reload()
          }
        })
        .catch((err) => {
          this.loading = false
          err.response.data.map((res) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: res.error
            });
          });
        });
    },

    createQRcode() {
      this.loading = true;
      this.$store
        .dispatch("pharmacy/QRcode_merchant_create", this.formData)
        .then((response) => {
          this.loading = false;
          if (response) {
            this.$toasted.success("QRCode Added Successfully", {
              duration: 5000
            });
            $("#ServicesModal").modal("hide");
            (this.formData.FullName = ""),
              (this.formData.Description = ""),
              (this.formData.cost = "");
            this.formData.IsActive = "";
          }
          this.getQRCodeItems();
        })
        .catch((err) => {
          this.loading = false;
          err.response.data.map((res) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: res.error
            });
          });
        });
    }
  },

  mounted() {
    this.getQRCodeItems();
    // Set the initial number of items
    this.totalRows = this.qrcode_generator.length;
    console.log(this.totalRows);
  }
};
</script>
